import { useLiveQuery } from 'dexie-react-hooks';
import { useState, useEffect } from 'react';
import { db } from './Data';
import {
  Button,
  Card,
  Checkbox,
  Col,
  Dropdown,
  Input,
  Popover,
  Row,
  Space,
} from 'antd';
import {
  BgColorsOutlined,
  CalendarOutlined,
  CheckSquareOutlined,
  DeleteOutlined,
  EllipsisOutlined,
  HighlightOutlined,
  IdcardOutlined,
  LayoutOutlined,
  LeftOutlined,
  MinusSquareOutlined,
  MoreOutlined,
  PlusOutlined,
  UnorderedListOutlined,
} from '@ant-design/icons';
import './TaskEditor.less';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
import { GithubPicker } from 'react-color';

interface Item {
  title: string;
  checked?: boolean;
  [key: string]: any;
  children?: Item[];
}

const defaultItem = '新任务';

export function TaskEditor() {
  const activeNote = useLiveQuery(() => db.getActiveNote());
  const options = useLiveQuery(() => db.getOptions());
  const [items, setItems] = useState<Item[]>([]);

  useEffect(
    () =>
      setItems(
        activeNote?.content && activeNote?.type === 'task'
          ? JSON.parse(activeNote?.content)
          : []
      ),
    [activeNote?.id]
  );

  function saveItems() {
    db.upsertNote(JSON.stringify(items), activeNote?.id);
  }

  if (activeNote?.type !== 'task') return null;
  const curStyle = options?.taskStyle || 'list';
  const ItemType = curStyle === 'list' ? ListItem : CardItem;
  const defaultCols = curStyle === 'list' ? 1 : 3;
  const cols =
    options?.[curStyle === 'list' ? 'listCols' : 'cardCols'] || defaultCols;

  const rows = [];
  const tasks = items.filter((item) =>
    options?.showFinished ? true : !item?.checked
  );
  for (let i = 0; i < tasks.length / cols; i++) {
    rows.push(
      <Row
        gutter={8}
        key={i}
        className={'note-task-list-row ' + options?.taskStyle}
      >
        {tasks.slice(i * cols, (i + 1) * cols).map((item: Item, index) => (
          <Col key={index} md={24 / cols} xs={24} >
            <ItemType
              showFinished={options?.showFinished}
              key={index}
              value={item}
              onChange={() => {
                setItems([...items]);
                saveItems();
              }}
              onDelete={() => {
                items.splice(items.indexOf(item), 1);
                setItems([...items]);
                saveItems();
              }}
            />
          </Col>
        ))}
      </Row>
    );
  }

  return (
    <div className="note-task">
      <div className="note-task-toolbar">
        <Button
          type="link"
          title="目录列表"
          icon={<LeftOutlined />}
          onClick={() => {
            db.switchMenuVisible();
          }}
        ></Button>
        <Button
          type="link"
          title="列表"
          icon={<UnorderedListOutlined />}
          onClick={() => db.setTaskLayout({ taskStyle: 'list' })}
        ></Button>
        <Button
          type="link"
          title="卡片"
          icon={<IdcardOutlined />}
          onClick={() => db.setTaskLayout({ taskStyle: 'card' })}
        ></Button>
        <Dropdown
          menu={{
            items: [
              {
                key: 1,
                label: '一列',
                onClick: () => db.setTaskLayout({ [curStyle + 'Cols']: 1 }),
              },
              {
                key: 2,
                label: '两列',
                onClick: () => db.setTaskLayout({ [curStyle + 'Cols']: 2 }),
              },
              {
                key: 2,
                label: '三列',
                onClick: () => db.setTaskLayout({ [curStyle + 'Cols']: 3 }),
              },
              {
                key: 3,
                label: '四列',
                onClick: () => db.setTaskLayout({ [curStyle + 'Cols']: 4 }),
              },
              {
                key: 4,
                label: '八列',
                onClick: () => db.setTaskLayout({ [curStyle + 'Cols']: 8 }),
              },
            ],
          }}
          trigger={['click']}
        >
          <Button type="link" title="布局" icon={<LayoutOutlined />}></Button>
        </Dropdown>
        <Button
          type="link"
          title="显示已完成任务"
          icon={
            options?.showFinished ? (
              <CheckSquareOutlined />
            ) : (
              <MinusSquareOutlined />
            )
          }
          onClick={() => {
            db.setTaskLayout({ showFinished: !options?.showFinished });
          }}
        ></Button>
        <Button
          type="link"
          title="添加任务"
          icon={<PlusOutlined />}
          onClick={() => {
            setItems([...items, { title: defaultItem }]);
            saveItems();
          }}
        ></Button>
      </div>
      <div className="note-task-list">
        <DndProvider
          backend={screen.width <= 768 ? TouchBackend : HTML5Backend}
        >
          <Scrollbars autoHide>{rows}</Scrollbars>
        </DndProvider>
      </div>
    </div>
  );
}

function ListItem(props: {
  showFinished?: boolean;
  value: Item;
  onChange: () => void;
  onDelete: () => void;
}) {
  return (
    <div
      className="note-task-list-group"
      style={{ backgroundColor: props.value.color }}
    >
      <div className="note-task-list-item">
        <Checkbox
          checked={props.value.checked}
          onChange={(e) => {
            props.value.checked = e.target.checked;
            props.onChange();
          }}
        ></Checkbox>
        <div
          className={
            'note-task-list-item-content' +
            (props.value.checked ? ' finished' : '')
          }
        >
          <Input.TextArea
            value={props.value.title}
            onChange={(e) => {
              props.value.title = e.target.value;
              props.onChange();
            }}
            bordered={false}
            autoSize
          />
        </div>
        <ItemToolbar {...props}></ItemToolbar>
      </div>
      <div
        className={
          'note-task-list-child' + (props.value.checked ? ' finished' : '')
        }
      >
        {props.value.children
          ?.filter((it) => (props.showFinished ? true : !it.checked))
          .map((it) => {
            return (
              <ListItem
                value={it}
                onChange={props.onChange}
                onDelete={() => {
                  props.value.children?.splice(
                    props.value.children.indexOf(it),
                    1
                  );
                  props.onChange();
                }}
              />
            );
          })}
      </div>
    </div>
  );
}

function CardItem(props: {
  showFinished?: boolean;
  value: Item;
  onChange: () => void;
  onDelete: () => void;
}) {
  return (
    <Card
      className={
        'note-task-list-card' + (props.value.checked ? ' finished' : '')
      }
      style={{ backgroundColor: props.value.color }}
      title={
        <Space>
          <Checkbox
            checked={props.value.checked}
            onChange={(e) => {
              props.value.checked = e.target.checked;
              props.onChange();
            }}
          ></Checkbox>
          <Input.TextArea
            value={props.value.title}
            onChange={(e) => {
              props.value.title = e.target.value;
              props.onChange();
            }}
            bordered={false}
            autoSize
          />
        </Space>
      }
      extra={<ItemToolbar {...props}></ItemToolbar>}
    >
      {props.value.children
        ?.filter((it) => (props.showFinished ? true : !it.checked))
        .map((it) => {
          return (
            <ListItem
              value={it}
              onChange={props.onChange}
              onDelete={() => {
                props.value.children?.splice(
                  props.value.children.indexOf(it),
                  1
                );
                props.onChange();
              }}
            />
          );
        })}
    </Card>
  );
}

function ItemToolbar(props: {
  value: Item;
  onChange: () => void;
  onDelete: () => void;
}) {
  const [showColorPicker, setShowColorPicker] = useState(false);

  return (
    <>
      <Button
        type="link"
        size="small"
        title="添加子任务"
        icon={<PlusOutlined />}
        onClick={() => {
          props.value.children = props.value.children || [];
          props.value.children.push({ title: defaultItem });
          props.onChange();
        }}
      ></Button>
      <Popover
        open={showColorPicker}
        content={
          <GithubPicker
            colors={[
              '#EFEBE9',
              '#FCE4EC',
              '#FFFDE7',
              '#FFE0B2',
              '#EEEEEE',
              '#F5F5F5',
              '#E0F2FE',
              '#FFFFFF',
            ]}
            triangle="hide"
            onChangeComplete={(e) => {
              props.value.color = e.hex === '#ffffff' ? undefined : e.hex;
              props.onChange();
              setShowColorPicker(false);
            }}
          ></GithubPicker>
        }
        overlayClassName="note-task-list-color"
        onOpenChange={() => {
          setShowColorPicker(!showColorPicker);
        }}
        trigger="click"
      >
        <Button
          size="small"
          type="link"
          title="设置背景色"
          icon={<HighlightOutlined />}
          onClick={() => setShowColorPicker(!showColorPicker)}
        ></Button>
      </Popover>
      <Dropdown
        menu={{
          items: [
            {
              key: 'delete',
              label: '删除',
              onClick: props.onDelete,
            },
            {
              key: 'calendar',
              label: '添加到日历',
            },
          ],
        }}
      >
        <Button
          type="link"
          title="更多"
          size="small"
          icon={<EllipsisOutlined />}
        ></Button>
      </Dropdown>
    </>
  );
}

export default TaskEditor;
